import React, { useEffect, useState } from "react";

const GetP = () => {
  return (
    <div className="Kjm">
      <iframe className="kkddd" src="https://docs.google.com/forms/d/e/1FAIpQLScVjMXxEvvM3wKFd4tB6s1xgNpzL6VqKaWCtKLdaWZYbMA9eQ/viewform?embedded=true" width="700" height="100" frameborder="0">Loading…</iframe>
    </div>
  );
};

export default GetP;
